// plugins/moment.js
import { defineNuxtPlugin } from "nuxt/app";
import moment from "moment";

export default defineNuxtPlugin((nuxtApp) => {
  // Make Moment.js available throughout your application

  return {
    provide: {
      moment: moment,
    },
  };
});
