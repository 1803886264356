import validate from "/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/src/middleware/auth.global.ts";
import manifest_45route_45rule from "/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "check-compliance": () => import("/src/middleware/check-compliance.ts"),
  guest: () => import("/src/middleware/guest.ts")
}