export default defineNuxtPlugin((nuxtApp) => {
  let formatCurrency = (amount: number, currency: string = "NGN"): string => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
    }).format(amount);
  };

  return {
    provide: {
      formatCurrency,
    },
  };
});
