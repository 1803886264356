export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.client) {
    const token = sessionStorage.getItem("spv-token");
    const loginState = sessionStorage.getItem("spv-login-state");

    // If layout is Dashboard
    if ((!token || !loginState) && to.meta.layout === "dashboard") {
      return navigateTo("/login");
    }

    // If Path matches  dashboard/onboarding
    if (
      (!token || !loginState) &&
      to.path.startsWith("/dashboard/onboarding")
    ) {
      return navigateTo("/login");
    }
  }
});
