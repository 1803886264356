import { defineStore } from "pinia";
import type { User, UserRole, ActiveSchool, School } from "~/types";

export const useStore = defineStore("sproutly_africa_state", {
  state: () => ({
    user: null as null | User, // Type the user state
    is_logged_in: false,
    token: null as null | string, // Type the token state
    team_id: null as null | string,
    active_school: null as null | ActiveSchool,
    schools: null as null | School[],
    totalUnreadNotifications: null as null | number,
    user_role: null as null | UserRole,
    set_pin_prompt: false,
  }),
  getters: {
    getUser: (state) => state.user,
    getLoginState: (state) => state.is_logged_in,
    getToken: (state) => state.token,
    getActiveSchool: (state) => state.active_school,
    getAllSchools: (state) => state.schools,
    getTotalUnreadNotifications: (state) => state.totalUnreadNotifications,
    getUserRole: (state) => state.user_role,
  },
  actions: {
    updateCompliance(payload: object) {
      if (this.active_school) {
        this.active_school.compliance = {
          ...this.active_school.compliance,
          ...payload,
        };
      }
    },
    updateUserFullname(firstname: string, lastname: string) {
      if (this.user) {
        this.user.firstname = firstname;
        this.user.lastname = lastname;
      }
    },
    updateSetPinPrompt(payload: boolean) {
      this.set_pin_prompt = payload;
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
