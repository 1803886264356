<template>
  <div class="tw-flex-none">
    <div v-html="svgContent" :class="`svg-icon ${iconClass}`" />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  iconColor: {
    type: String,
    default: "currentColor", // Use current color by default
  },
  iconClass: {
    type: String,
    default: "",
  },
  size: {
    type: String,
    default: "20px",
  },
});

const modules = import.meta.glob("../../assets/images/sicons/**/*.svg", {
  query: "?raw",
  import: "default",
});

const svgContent = ref<string>("");

const loadSvg = async () => {
  if (import.meta.client) {
    const svgPath = `../../assets/images/sicons/${props.name}.svg`;

    if (typeof modules[svgPath] === "function") {
      const icon = await modules[svgPath]();
      let parser = new DOMParser();
      let doc = parser.parseFromString(icon as string, "image/svg+xml");
      doc.querySelectorAll("svg").forEach((svg) => {
        svg.setAttribute("width", props.size);
        svg.setAttribute("height", props.size);
      });
      doc.querySelectorAll("path").forEach((path) => {
        path.setAttribute("stroke", props.iconColor);
      });
      let serializer = new XMLSerializer();
      svgContent.value = serializer.serializeToString(doc);
    } else {
      console.error("SVG file not found:", svgPath);
    }
  }
};

watchEffect(() => {
  loadSvg();
});
</script>

<style lang="scss">
/* Add any additional styling here */
</style>
