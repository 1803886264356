import { Realtime } from "ably";

export default defineNuxtPlugin((nuxtApp) => {
  const { ABLY_API_KEY } = useRuntimeConfig().public;

  if (!ABLY_API_KEY) {
    console.error("ABLY_API_KEY is not defined in the environment variables.");
    return;
  }

  let ably = new Realtime({
    key: ABLY_API_KEY,
  });

  ably.connection.once("connected", () => {
    console.log("Connected to Ably!");
  });

  ably.connection.on("failed", (error) => {
    console.error("Failed to connect to Ably:", error);
  });

  return {
    provide: {
      ably: ably,
    },
  };
});
