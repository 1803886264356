<template>
  <div>
    <nav
      :class="`${fixed ? 'tw-fixed tw-z-[10] tw-w-full' : ''}`"
      class="tw-select-none tw-border-b tw-border-gray-100 tw-bg-white"
    >
      <div class="tw-m-auto tw-w-11/12 2xl:tw-w-10/12">
        <section class="tw-flex tw-items-center tw-justify-between tw-py-5">
          <div
            class="tw-flex tw-w-full tw-items-center tw-justify-between tw-space-x-2"
          >
            <NuxtLink to="/">
              <AppLogo type="dark" />
            </NuxtLink>
          </div>

          <div
            v-if="store.is_logged_in"
            class="tw-hidden tw-flex-none tw-items-center tw-space-x-2 md:tw-flex lg:tw-flex"
          >
            <AppButton
              arialabel="Logout"
              type="outline"
              @click.prevent="logoutMerchantAccount"
              >Logout</AppButton
            >
          </div>

          <div
            v-else
            class="tw-hidden tw-flex-none tw-items-center tw-space-x-2 md:tw-flex lg:tw-flex"
          >
            <NuxtLink to="/login">
              <AppButton arialabel="Login">Login</AppButton>
            </NuxtLink>

            <NuxtLink to="/sign-up">
              <AppButton type="dark" arialabel="Sign Up">Sign Up</AppButton>
            </NuxtLink>
          </div>

          <div class="tw-pl-2 md:tw-hidden">
            <AppButton @click="isActive = true" arialabel="Menu">
              <Icon name="ci:hamburger-lg" size="25px" />
            </AppButton>
          </div>
        </section>
      </div>
    </nav>

    <!-- Side Navigation -->
    <div
      v-show="isActive"
      @click.self="isActive = false"
      class="tw-fixed tw-left-0 tw-top-0 tw-z-[10] tw-h-screen tw-w-full tw-cursor-pointer tw-bg-black/50 md:tw-hidden"
    >
      <div class="tw-h-screen tw-w-[75%] tw-bg-white lg:tw-w-[50%]">
        <div class="tw-border">
          <div class="tw-p-5">
            <AppLogo type="dark" />
          </div>
        </div>

        <div v-if="store.is_logged_in">
          <div class="tw-border-b md:tw-hidden">
            <div class="tw-p-5">
              <AppButton
                @click.prevent="logoutMerchantAccount"
                type="outline"
                :full="true"
                arialabel="Logout"
                >Logout</AppButton
              >
            </div>
          </div>
        </div>

        <div v-else>
          <div class="tw-border-b md:tw-hidden">
            <div class="tw-p-5">
              <NuxtLink to="/login">
                <AppButton type="outline" :full="true" arialabel="Login"
                  >Login</AppButton
                >
              </NuxtLink>
            </div>
          </div>

          <div class="tw-border-b md:tw-hidden">
            <div class="tw-p-5">
              <AppButton
                type="dark"
                :full="true"
                arialabel="Sign Up"
                @click.prevent="$router.push({ path: '/sign-up' })"
                >Sign Up</AppButton
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- If Fixed -->
    <div class="tw-pb-16" v-if="fixed"></div>
  </div>
</template>

<script setup lang="ts">
import { useStore } from "~/store";
const store = useStore();
const { $router } = useNuxtApp();

interface PropTypes {
  fixed?: boolean;
}

defineProps<PropTypes>();

interface LinkType {
  title?: string;
  url?: string;
}

const isActive = ref(false);

const logoutMerchantAccount = async () => {
  let confirmation = confirm("Are you sure you want to logout?");
  if (confirmation) {
    store.$reset();
    sessionStorage.clear();
    $router.push("/login");
  } else {
    return;
  }
};
</script>

<style lang="scss" scoped></style>
