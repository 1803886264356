import { useToast } from "vue-toast-notification";
const toast = useToast();

const defaultDuration = 3000;
const defaultPosition = "top";

export default defineNuxtPlugin(() => {
  const showToast = (type, message, options) => {
    toast[type](message, {
      ...options,
      duration: options?.duration || defaultDuration,
      position: options?.position || defaultPosition,
    });
  };

  return {
    provide: {
      toast: {
        error: (message, options) => showToast("error", message, options),
        success: (message, options) => showToast("success", message, options),
        info: (message, options) => showToast("info", message, options),
        warning: (message, options) => showToast("warning", message, options),
        default: (message, options) => showToast("default", message, options),
      },
    },
  };
});
