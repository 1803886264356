<template>
  <div>
    <button
      :class="[btnClass, { 'btn-disabled': props.disabled }]"
      :type="buttonType"
      :aria-label="props.arialabel"
      :disabled="props.disabled"
      :aria-disabled="props.disabled ? 'true' : 'false'"
      @click="handleClick"
    >
      <slot></slot>
    </button>
  </div>
</template>

<script setup lang="ts">
type ButtonType = {
  size?: "small" | "medium" | "large" | "default";
  disabled?: boolean;
  type?:
    | "default"
    | "primary"
    | "secondary"
    | "white"
    | "dark"
    | "outline"
    | "error"
    | "warning"
    | "gray"
    | "olive"
    | "text-error";

  full?: boolean;
  buttonType?: "submit" | "reset" | "button";
  arialabel?: string;
};

const props = withDefaults(defineProps<ButtonType>(), {
  size: "default",
  disabled: false,
  type: "default",
  full: false,
  class: "",
  to: "",
  arialabel: "button",
});

const emit = defineEmits(["click"]);

const btnClass = computed(() => {
  return `btn btn-${props.type} btn-${props.size} ${
    props.disabled ? "btn-disabled" : "tw-cursor-pointer"
  } ${props.full ? "tw-w-full" : ""} ${props.class}`;
});

const handleClick = (event: any) => {
  if (props.disabled) {
    // Button is disabled, do nothing
    return;
  }

  emit("click", event);
};
</script>

<style lang="postcss" scoped>
@tailwind components;

@layer components {
  .btn {
    @apply tw-relative tw-rounded-lg tw-px-6 tw-py-2 tw-font-semibold;
  }

  .btn i {
    position: absolute;
    left: 8px; /* Adjust as needed */
  }

  .btn-default {
    @apply tw-bg-transparent tw-text-gray-500 hover:tw-text-black focus:tw-ring-2 focus:tw-ring-gray-300;
  }

  .btn-white {
    @apply tw-bg-white tw-text-gray-900 tw-shadow-sm hover:tw-bg-primary-50 hover:tw-text-gray-900 focus:tw-ring-2 focus:tw-ring-primary-200;
  }

  .btn-dark {
    @apply tw-bg-gray-900 tw-text-white tw-shadow-sm hover:tw-bg-black hover:tw-text-white focus:tw-ring-2 focus:tw-ring-gray-300;
  }

  .btn-primary {
    @apply tw-bg-primary-600 tw-text-white tw-shadow-sm hover:tw-bg-primary-700 hover:tw-text-white focus:tw-ring-2 focus:tw-ring-primary-300;
  }

  .btn-secondary {
    @apply tw-bg-primary-50 tw-text-primary-600 tw-shadow-sm hover:tw-bg-primary-100 hover:tw-text-primary-600 focus:tw-ring-2 focus:tw-ring-primary-200;
  }

  .btn-error {
    @apply tw-bg-error-50 tw-text-error-600 tw-shadow-sm hover:tw-bg-error-100 hover:tw-text-error-600 focus:tw-ring-2 focus:tw-ring-primary-200;
  }

  .btn-warning {
    @apply tw-bg-warning-50 tw-text-warning-600 tw-shadow-sm hover:tw-bg-warning-100 hover:tw-text-warning-600 focus:tw-ring-2 focus:tw-ring-primary-200;
  }

  .btn-outline {
    @apply tw-border tw-border-gray-300 tw-bg-white tw-text-gray-700 tw-shadow-sm hover:tw-bg-gray-50 hover:tw-text-gray-700 hover:tw-text-gray-800 focus:tw-ring-2 focus:tw-ring-gray-100;
  }

  .btn-olive {
    @apply tw-bg-olive-600 tw-text-gray-900 tw-shadow-sm hover:tw-bg-olive-700 hover:tw-text-gray-900 focus:tw-ring-2 focus:tw-ring-olive-300;
  }

  .btn-gray {
    @apply tw-bg-gray-50 tw-text-gray-900 tw-shadow-sm hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-ring-2 focus:tw-ring-gray-200;
  }
  .btn-text-error {
    @apply tw-bg-transparent tw-text-error-500 hover:tw-text-error-600 focus:tw-ring-2 focus:tw-ring-error-300;
  }
  .btn-small {
    font-size: 14px;
  }

  .btn-medium {
    font-size: 16px;
  }

  .btn-large {
    font-size: 18px;
  }

  .btn-disabled {
    @apply tw-pointer-events-auto tw-cursor-not-allowed tw-opacity-60;
  }
}
</style>
