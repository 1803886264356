<template>
  <div>
    <NuxtLink to="/">
      <div :class="[type === 'dark-round' ? 'tw-w-full' : 'tw-w-[150px]']">
        <NuxtImg
          :src="getLogo()"
          :alt="'logo'"
          width="150px"
          height="100px"
          format="webp"
        />
      </div>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
const DarkRoundLogo =
  "https://files.sproutly.africa/assets/images/logo/dark-round.svg";
const LightLogo = "https://files.sproutly.africa/assets/images/logo/light.svg";
const DarkLogo = "https://files.sproutly.africa/assets/images/logo/dark.svg";

interface PropTypes {
  type: "dark-round" | "dark-square" | "dark" | "light";
}

const props = withDefaults(defineProps<PropTypes>(), {
  type: "dark",
});

const getLogo = () => {
  switch (props.type) {
    case "dark-round":
      return DarkRoundLogo;
    case "light":
      return LightLogo;
    default:
      return DarkLogo;
  }
};
</script>

<style scoped></style>
