<template>
  <NuxtLayout name="404">
    <div class="tw-min-h-screen tw-bg-gray-50">
      <NavbarMain :fixed="true" />

      <section class="tw-py-16">
        <div class="stable-alignment">
          <div class="tw-grid tw-items-center tw-gap-10 lg:tw-grid-cols-2">
            <div class="tw-space-y-5">
              <p class="tw-font-semibold tw-text-gray-700">404 error</p>
              <h3
                class="tw-text-3xl tw-font-bold tw-text-gray-900 md:tw-text-4xl lg:tw-text-6xl"
              >
                Page not found
              </h3>
              <p class="tw-text-gray-500">
                Sorry, the page you are looking for doesn't exist.Here are some
                helpful links:
              </p>
              <div
                class="tw-items-center tw-space-y-5 lg:tw-flex lg:tw-space-x-5 lg:tw-space-y-0"
              >
                <AppButton
                  type="outline"
                  :full="true"
                  @click.prevent="$router.go(-1)"
                >
                  <div class="tw-flex tw-items-center tw-space-x-2">
                    <AppIcon name="arrow-left" />
                    <span>Go back</span>
                  </div>
                </AppButton>
                <AppButton
                  @click.prevent="$router.push({ path: '/' })"
                  type="dark"
                  :full="true"
                  class="tw-order-first lg:tw-order-last"
                >
                  Take me home
                </AppButton>
              </div>
            </div>
            <div>
              <NuxtImg
                src="https://files.sproutly.africa/assets/images/error/404.png"
                alt="error-404"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  </NuxtLayout>
</template>
<script setup>
useHead({
  title: "404 | Page not found - Sproutly Africa",
});
</script>
<style lang="scss" scoped></style>
